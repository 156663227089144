import { Country } from '../types/enums/Country';

export function getLocalizedPrice(amount: number | undefined) {
    const currentCountry = useCurrentCountry();
    const { locale } = useI18n();

    if (!amount) return undefined;

    const amountString = Math.round(amount).toLocaleString(locale.value);

    if (currentCountry.value === Country.CH) {
        return `${amountString} CHF`;
    }

    return `€ ${amountString}`;
}
